
import { Component, Vue } from 'vue-property-decorator'
import * as Echarts from 'echarts'
import NoData from '@/components/NoData.vue'

@Component({
  components: { NoData }
})
export default class extends Vue {
  private planInfo: any = {}

  get planId () {
    return this.$route.params.planId
  }

  get finisned () {
    if (this.planInfo.taskList) {
      return this.planInfo.taskList.filter((t: any) => t.taskState === '1').length
    }
    return 0
  }

  _chart: any = null
  detail = {}
  active = ''
  created () {
    this.planId && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.operationmanage.selectYhDailyPlanByPlanId, {
      planId: this.planId
    }).then(res => {
      this.planInfo = res || []
      this.renderEchart(this.finisned, this.planInfo.taskList.length || 0)
    })
  }

  onEdit () {
    this.$router.push({ name: 'PlanReportEdit', params: { planId: this.planId } })
  }

  renderEchart (value: any, total: any) {
    this.$nextTick(() => {
      this._chart = Echarts.init(document.getElementById('echart') as any)
      const option = {
        series: [{
          name: '11',
          type: 'pie',
          clockWise: false,
          radius: ['75%', '100%'],
          itemStyle: {
            normal: {
              color: '#449BFF',
              shadowBlur: 0,
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [
            {
              value: value,
              label: {
                normal: {
                  formatter: (params: any) => {
                    return '{title' + `|${(+params.value / total * 100).toFixed(1)}` + '%}' + '\n' + '{name|完成率}'
                  },
                  rich: {
                    name: {
                      color: '#666666',
                      fontSize: 12,
                      lineHeight: 20
                    }
                  },
                  position: 'center',
                  show: true,
                  textStyle: {
                    fontSize: 26,
                    color: '#449BFF'
                  }
                }
              }
            },
            {
              value: total - value === 0 ? 100 : total - value,
              name: 'invisible',
              itemStyle: {
                normal: {
                  color: '#E0E0E0'
                }
              }
            }
          ]
        }]
      }
      this._chart.clear()
      this._chart.setOption(option)
    })
  }

  onDelete () {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.operationmanage.deleteYhDailyPlan, { planId: this.planId }).then(() => {
        this.$message.success('删除成功')
        this.$router.back()
      })
    })
  }

  handleChange (val: string) {
    this.detail = []
    if (val) {
      this.$axios.get(this.$apis.operationmanage.selectYhDailyWorkByList, { taskId: val }).then((res) => {
        this.detail = res || []
      })
    }
  }
}
